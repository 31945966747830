// extracted by mini-css-extract-plugin
export var root = "thing-module--root--39BCP";
export var bgImg = "thing-module--bgImg--2WrPc";
export var rootGrid = "thing-module--rootGrid--390Iz";
export var description = "thing-module--description--2-XbL";
export var descriptionText = "thing-module--descriptionText--COzEx";
export var btnRow = "thing-module--btnRow--2h0-0";
export var btnLink = "thing-module--btnLink--EtWO8";
export var gridItemCodeLink = "thing-module--gridItemCodeLink--2YWGN";
export var codeLink = "thing-module--codeLink--3VCEq";
export var title = "thing-module--title--4N46t";
export var closed = "thing-module--closed--2A37J";
export var open = "thing-module--open--m2zeP";
export var iconGithub = "thing-module--iconGithub--3nMOp";