import React from "react"
import PropTypes from "prop-types"
import { Grid, Button, IconButton } from "@material-ui/core"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import IconGithub from "./IconGitHub"
import * as css from "@css/modules/thing.module.scss"

const Thing = ({ itemOpened, activeItemId, thing: { html, frontmatter } }) => {
  const { id, title, link, codeLinks, imgHighRes, imgLowRes } = frontmatter
  const image = getImage(imgHighRes)
  const prevImg = getImage(imgLowRes)
  const handleThingClick = () => {
    itemOpened(id)
  }
  const isOpen = activeItemId === id
  return (
    <Grid
      item
      xs={12}
      id={`thing-container-${id}`}
      className={`${css.root} ${isOpen ? css.open : css.closed}`}
      role="button"
      tabIndex="0"
      onKeyDown={handleThingClick}
      onClick={handleThingClick}
    >
      <GatsbyImage
        alt={title}
        image={prevImg}
        className={css.bgImg}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      {isOpen && (
        <GatsbyImage
          alt={title}
          image={image}
          className={css.bgImg}
          style={{ position: "absolute", width: "100%", height: "100%" }}
        />
      )}

      <Grid container spacing={3} className={css.rootGrid}>
        <Grid item xs={12} sm={6}>
          <h2 className={`${css.title} ${!isOpen ? css.closed : ""}`}>
            {title}
          </h2>
        </Grid>
        {isOpen && (
          <Grid item xs={12} sm={6}>
            <div className={`${css.description} ${isOpen ? css.open : ""}`}>
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className={css.descriptionText}
              />
              {(link || codeLinks) && (
                <Grid container spacing={0} className={css.btnRow}>
                  <Grid item xs={12} sm={6} align="left">
                    {link && (
                      <Button
                        variant="contained"
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="large"
                        className={css.btnLink}
                        disabled={!isOpen}
                      >
                        See It
                      </Button>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    align="right"
                    className={css.gridItemCodeLink}
                  >
                    {codeLinks.map((link, idx) => (
                      <div className="growBtn" key={idx}>
                        <IconButton
                          href={link}
                          size="small"
                          variant="outlined"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={css.codeLink}
                          disabled={!isOpen}
                        >
                          <IconGithub />
                        </IconButton>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

Thing.propTypes = {
  thing: PropTypes.shape({
    html: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      id: PropTypes.number.isRequired,
      link: PropTypes.string,
      codeLinks: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string.isRequired,
      imgHighRes: PropTypes.objectOf(PropTypes.any).isRequired,
      imgLowRes: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
  }),
  itemOpened: PropTypes.func.isRequired,
  activeItemId: PropTypes.number.isRequired,
}
export default Thing
