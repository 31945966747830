import React from "react"

const IconGithub = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" heigth="30" viewBox="0 0 30 30">
      <path fill="#fff" d="M0,15c0-4.1,1.5-7.7,4.4-10.6C7.3,1.5,10.9,0,15,0c4.1,0,7.7,1.5,10.6,4.4C28.5,7.3,30,10.9,30,15 s-1.5,7.7-4.4,10.6S19.1,30,15,30c-4.1,0-7.7-1.5-10.6-4.4C1.5,22.7,0,19.1,0,15z M1.6,15c0,3.7,1.3,6.8,3.9,9.4 c2.6,2.6,5.8,3.9,9.4,3.9c3.7,0,6.8-1.3,9.4-3.9s3.9-5.8,3.9-9.4c0-3.7-1.3-6.8-3.9-9.4c-2.6-2.6-5.8-3.9-9.4-3.9 c-3.7,0-6.8,1.3-9.4,3.9C2.9,8.2,1.6,11.3,1.6,15z M5.9,18.2c-0.1-0.1-0.1-0.2,0-0.2c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.4,0.1 c0.3,0.1,0.6,0.4,0.9,0.8c0.3,0.4,0.6,0.7,0.8,0.8c0.9,0.8,1.9,0.8,2.9,0.2c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.3-0.3,0.5-0.4 c0.2-0.1,0.4-0.2,0.8-0.4c-1.3-0.1-2.3-0.3-3.1-0.7c-0.8-0.3-1.4-0.8-1.9-1.3c-0.6-0.7-1-1.6-1.1-2.8c-0.1-1.1,0-2.2,0.3-3.1 c0.2-0.6,0.6-1.1,1-1.6c-0.3-1-0.3-2.1,0.2-3.3c1.2,0.1,2.4,0.5,3.4,1.3c2-0.5,4-0.5,6.1-0.1c0.3-0.2,0.7-0.4,1.3-0.7 c0.6-0.3,1.3-0.5,2.1-0.5c0.2,0.4,0.3,0.9,0.3,1.5s0,1.1-0.1,1.6c0.9,0.9,1.4,2.2,1.4,3.7c0,1.2-0.2,2.3-0.6,3.1s-1.2,1.5-2.3,2.1 c-0.7,0.4-1.7,0.6-2.9,0.6c0.5,0.3,0.9,0.6,1.2,0.8c0.3,0.3,0.4,0.8,0.5,1.4v1.8l0.1,1.8c0.1,0.2,0.3,0.4,0.4,0.5 c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.1,0.3c0,0.1-0.2,0.2-0.5,0.2c-0.7,0-1.2-0.2-1.6-0.7c-0.1-0.2-0.2-0.4-0.2-0.7v-2.8 c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.1-0.3-0.2-0.5-0.3v3.7c0,0.7,0.1,1.1,0.2,1.3s0.3,0.4,0.3,0.6c0,0,0,0.1-0.1,0.1 c-0.1,0-0.3,0-0.5-0.1c-0.5-0.1-0.9-0.4-1.1-0.8s-0.3-0.8-0.3-1.3V20h-0.8v3.6c0,0.5-0.1,0.9-0.3,1.3c-0.3,0.5-0.8,0.8-1.5,0.9 c-0.1,0-0.2-0.1-0.2-0.1c0,0,0.2-0.2,0.4-0.6c0-0.1,0.1-0.2,0.1-0.5c0.1-0.2,0.1-0.5,0.1-0.8v-3.7c-0.2,0-0.3,0.1-0.4,0.3 s-0.2,0.4-0.2,0.7V24c0,0.3-0.1,0.5-0.2,0.7c-0.4,0.5-0.9,0.7-1.6,0.7c-0.3,0-0.4-0.1-0.5-0.2c0-0.1,0-0.1,0-0.2s0.1-0.1,0.3-0.2 s0.2-0.1,0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.6,0.1-1.2s-0.1-1.1,0-1.3c-1,0.4-2.1,0.2-3.1-0.5c-0.3-0.3-0.6-0.8-0.9-1.3 C7,19.3,6.6,18.8,5.9,18.2L5.9,18.2z" />
    </svg>
  )
}

export default IconGithub
