import React, { useState } from "react"
import { graphql } from "gatsby"
import Thing from "@components/Thing"
import Layout from "@layouts/Layout"
import PropTypes from "prop-types"

export default function Home({ data }) {
  const [activeItemId, setActiveItemId] = useState(-1)
  const itemOpened = id => {
    setActiveItemId(id)
  }
  const { things } = data?.allMarkdownRemark
  return (
    <Layout>
      <div>
        {things.map((thing, idx) => (
          <Thing
            key={idx}
            thing={thing}
            itemOpened={itemOpened}
            activeItemId={activeItemId}
          />
        ))}
      </div>
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          html: PropTypes.string,
          frontmatter: PropTypes.shape({
            id: PropTypes.number.isRequired,
            link: PropTypes.string.isRequired,
            codeLinks: PropTypes.arrayOf(PropTypes.string),
            title: PropTypes.string.isRequired,
            imgFeatured: PropTypes.objectOf(PropTypes.any).isRequired,
          }),
        })
      ),
    }),
  }),
}

export const query = graphql`
  query ThingsQuery {
    allMarkdownRemark(sort: { fields: frontmatter___id, order: DESC }) {
      things: nodes {
        html
        frontmatter {
          id
          slug
          link
          codeLinks
          title
          imgHighRes {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
            }
          }
          imgLowRes {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
